<template>
  <div class="header">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="领取时间">
        <el-date-picker
          v-model="form.time"
          size="small"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 340px"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="使用状态">
        <el-select v-model="form.state" size="small" style="width: 130px" placeholder="请选择">
          <el-option label="全部" value=""> </el-option>
          <el-option label="待使用" value="1"> </el-option>
          <el-option label="已使用" value="2"> </el-option>
          <el-option label="已过期" value="3"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input
          v-model="form.phoneNumber"
          size="small"
          style="width: 130px"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-link type="primary" style="margin-right: 10px" @click="checkFold">{{
          fold ? '收起' : '展开'
        }}</el-link>
        <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
        <el-button type="primary" size="small" @click="reset">重置</el-button>
      </el-form-item>
      <template>
        <div
          v-show="fold"
          :class="fold ? 'fold' : 'unfold'"
          style="
            padding: 20px;
            border-radius: 4px;
            box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
          "
        >
          <el-form-item label="卡券种类">
            <el-select
              v-model="form.couponKind"
              size="small"
              style="width: 130px"
              placeholder="请选择"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option label="优惠券" value="1"> </el-option>
              <el-option label="产品券" value="2"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="卡券类型">
            <el-select
              v-model="form.couponType"
              size="small"
              style="width: 130px"
              placeholder="请选择"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option label="立减券" value="1"> </el-option>
              <el-option label="满减券" value="2"> </el-option>
              <el-option label="折扣券" value="3"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="适用范围">
            <el-select
              v-model="form.companyScope"
              size="small"
              style="width: 130px"
              placeholder="请选择"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option label="企业员工" value="2"> </el-option>
              <el-option label="平台用户" value="1"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="生效时间">
            <el-date-picker
              v-model="form.time1"
              size="small"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 340px"
            >
            </el-date-picker>
          </el-form-item>
        </div>
      </template>
    </el-form>
  </div>
</template>

<script>
import { queryChildsById } from '@/api'
import moment from 'moment'
export default {
  name: 'PageHeader',
  components: {},
  data() {
    return {
      form: {
        couponKind: '',
      },
      fold: false,
      customerCate: [],
    }
  },
  mounted() {
    this.queryChildsById()
  },
  methods: {
    queryChildsById() {
      queryChildsById({
        data: { key: 'message_scope' },
      }).then((res) => {
        this.customerCate = res.data.childs
      })
    },
    onSubmit() {
      const data = { ...this.form }
      if (data.time) {
        data.beginTakeTime = moment(data.time[0]).format('YYYY-MM-DD HH:mm:ss')
        data.endTakeTime = moment(data.time[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      if (data.time1) {
        data.beginValidStartTime = moment(data.time1[0]).format('YYYY-MM-DD HH:mm:ss')
        data.endValidStartTime = moment(data.time1[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      delete data.time
      delete data.time1
      this.$emit('search', data)
    },
    reset() {
      this.form = {}
      this.$emit('search', {})
    },

    checkFold() {
      this.fold = !this.fold
    },
  },
}
</script>
<style lang="less" scoped></style>
