<template>
  <el-table ref="multipleTable" v-loading="loading" :data="tableData" border style="width: 100%">
    <el-table-column prop="userId" label="用户ID" min-width="80"> </el-table-column>
    <el-table-column prop="phoneNumber" label="手机号" min-width="150"> </el-table-column>
    <el-table-column prop="takeTime" label="领取时间" min-width="160"> </el-table-column>
    <el-table-column prop="couponName" label="卡券名称" min-width="180"> </el-table-column>

    <el-table-column prop="couponKind" label="卡券种类" width="100">
      <template slot-scope="scope">
        <span>
          {{ scope.row.couponKind == 1 ? '优惠券' : scope.row.couponKind == 2 ? '产品券' : '' }}
        </span>
      </template>
    </el-table-column>

    <el-table-column prop="couponTypeName" label="卡券类型" min-width="100"> </el-table-column>

    <el-table-column label="优惠折扣（折）" min-width="120">
      <template slot-scope="scope"> {{ Number(scope.row.couponDiscount).toFixed(1) }} </template>
    </el-table-column>

    <el-table-column prop="withAmount" label="使用门槛金额（￥）" min-width="120">
      <template slot-scope="scope"> {{ scope.row.withAmount }} </template>
    </el-table-column>
    <el-table-column prop="couponAmount" label="优惠券金额（￥）" min-width="120">
      <template slot-scope="scope"> {{ scope.row.couponAmount }} </template>
    </el-table-column>
    <el-table-column prop="validStartTime" label="生效时间" min-width="160"> </el-table-column>
    <el-table-column prop="validEndTime" label="失效时间" min-width="160">
      <template slot-scope="scope">
        {{ scope.row.isNeverExpires == 1 ? '永久有效' : scope.row.validEndTime }}
      </template>
    </el-table-column>
    <el-table-column prop="stateName" label="使用状态" min-width="100">
      <template slot-scope="scope">
        <span :style="{ color: getStateColor(scope.row.state) }">
          {{ scope.row.state == 1 ? '待使用' : scope.row.stateName }}</span
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mixin } from '@/mixins'
export default {
  mixins: [mixin],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectData: [],
    }
  },
  methods: {
    detail(row, num) {
      this.$emit('detail', { ...row, num })
    },
    cancel(row) {
      this.$emit('cancel', row)
    },
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v)
      })
    },
    toggleSelection(rows) {
      if (rows) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
        // if (this.selectData.length > 0) {
        //   console.log(this.getArrDifference(this.tableData, this.selectData));
        //   this.getArrDifference(this.tableData, this.selectData).forEach(
        //     (row) => {
        //       this.$refs.multipleTable.toggleRowSelection(row, true);
        //     }
        //   );
        //   this.selectData.forEach((row) => {
        //     this.$refs.multipleTable.toggleRowSelection(row, false);
        //   });
        // }
      }
    },
    handleSelectionChange(val) {
      this.selectData = val
      this.$emit('handleSelectionChange', val)
    },
  },
}
</script>

<style lang="less" scoped></style>
