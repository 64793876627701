<template>
  <div>
    <page-header @search="search" />
    <div class="table">
      <div class="btn">
        <el-button size="small" @click="exportTable">导出</el-button>
      </div>
      <Table
        ref="table"
        :table-data="tableData"
        :total="total"
        :loading="loading"
        @handleSelectionChange="handleSelectionChange"
      />
      <Pages
        :current-page="form.pageNumber"
        :total="total"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import pageHeader from './header'
import Table from './table'
import Pages from '@/components/pages'
import { getCouponList, getCouponListExport } from '@/api/card'
import { downloadFile } from '@/utils/fileDownload'

export default {
  components: {
    pageHeader,
    Table,
    Pages,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      multipleSelection: [],
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      loading: false,
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  watch: {},
  mounted() {
    this.getTableData()
  },
  methods: {
    getTableData() {
      this.loading = true
      getCouponList(this.form)
        .then((res) => {
          this.loading = false
          this.tableData = res.data.content
          this.total = Number(res.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    search(data) {
      this.form.pageNumber = 1
      this.form.data = data
      this.getTableData()
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    exportTable() {
      const data = { ...this.form }
      data.data.isExport = 1
      this.loading = true
      getCouponListExport(data)
        .then((res) => {
          this.loading = false
          downloadFile(res, '卡券领取记录')
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #999;
  height: 46px;
  background-color: #f9f9f9;
  padding-right: 20px;
  border: #e9e9e9 1px solid;
  margin-bottom: 15px;
  .text {
    padding: 0 4px;
    font-size: 14px;
  }
}
::v-deep .is-disabled {
  .el-checkbox__inner {
    cursor: pointer !important;
    background-color: #fff !important;
  }
  .is-checked .el-checkbox__inner {
    background-color: #409eff !important;
  }
}
::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner::after {
  cursor: pointer !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #fff;
}
</style>
